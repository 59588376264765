<template>
  <div class="container">
    <div class="left_icon"><van-icon name="arrow-left" color="#fff" @click="handleClick"/></div>
    <div class="container_title">个人信息资料</div>
    <div class="container_box">
      <infoForm @formClick="formClick" :btn-flag="btnFlag" :btn-flag1="btnFlag1" @btnInfo="btnInfo"></infoForm>
    </div>
  </div>
</template>

<script>
import infoForm from '@/components/infoForm'
import { areaList } from '@vant/area-data'
import {
  getInfo,
  getRegister,
  profileData,
  uploadData
} from '@/api'
export default {
  name: "info",
  data() {
    return {
      areaList,
      value: '',
      status: false,
      show: false,
      cascaderValue: '',
      btnFlag: false,
      btnFlag1: false
    }
  },
  components: {
    infoForm
  },
  created() {
    if (localStorage.getItem('token') != null) {
      this.get_Info()
    } else {
      this.btnFlag1 = true
    }
  },
  methods: {
    get_Info() {
      getInfo({
        token: localStorage.getItem('token')
      }).then(res => {
        console.log(res.data.data.is_build)
        let is_build = res.data.data.is_build
        if (is_build == 0) {
          this.btnFlag = true
          this.btnFlag1 = true
        }
        if (is_build == 1) {
          this.btnFlag = false
        }
      })
    },
    onOpen() {
      this.show = true
    },
    onClose() {
      console.log(this)
      this.status = false
      this.show = false
    },
    handleClick() {
      this.$router.go(-1)
    },
    formClick(e) {
      if (localStorage.getItem('token') == null) {
        getRegister(e).then(res => {
          if (res.data.code == 0) {
            this.$toast.fail(res.data.msg)
          } else {
            if (res.data.data.userinfo?.is_build == 0) {
              this.btnFlag = true
            } else if (res.data.data.userinfo?.is_build == 1) {
              this.btnFlag = false
            }
            localStorage.setItem('token', res.data.data.userinfo.token)
            localStorage.setItem('flag', '0')
            sessionStorage.removeItem('list')
            sessionStorage.removeItem('list1')
            sessionStorage.removeItem('list2')
            this.$toast.success(res.data.msg)
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          }
        })
      } else if (localStorage.getItem('token') != null) {
        profileData(e).then(res => {
          if (res.data.code == 0) {
            this.$toast.fail(res.data.msg)
          } else {
            if (res.data.data?.is_build == 0) {
              this.btnFlag = true
            } else if (res.data.data?.is_build == 1) {
              this.btnFlag = false
            }
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('flag', '0')
            sessionStorage.removeItem('list')
            sessionStorage.removeItem('list1')
            sessionStorage.removeItem('list2')
            this.$toast.success(res.data.msg)
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          }
        })
      }

    },
    handleUpload() {
      if (localStorage.getItem('token') == null) {
        this.$toast.fail('请登录后操作!')
        return
      }
      uploadData().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$toast.fail(res.data.msg)
        } else {
          this.show2 = true
          this.src = 'https://api.qingshaoniandati.kc87.com' + res.data.data
        }
      })
    },
    btnInfo(e) {
      if (e == 0) {
        this.btnFlag = true
        this.btnFlag1 = true
      }
      if (e == 1) {
        this.btnFlag = true
        this.btnFlag1 = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 0 25px 0 25px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  // background: url("https://qingshaoniandati.s3.cn-south-1.jdcloud-oss.com/infobj.png") no-repeat center 0;
  background-size: cover;
  .left_icon {
    box-sizing: border-box;
    padding-top: 20px;
  }
  &_title {
    box-sizing: border-box;
    width: 100%;
    padding: 65px 0 65px 0;
    text-align: center;
    font-size: 55px;
    color: #fff;
    letter-spacing: 0.3em;
  }
  &_box {
    padding: 48px 24px 48px 25px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #fff;
    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 38px;
      .item_label {
        font-size: 25px;
        font-weight: bold;
      }
      img {
        width: 52px;
        height: 52px;
      }
      .item {
        display: flex;
        align-items: center;
        .inp {
          width: 455px;
          height: 43px;
          border: 1PX solid #969495;
          border-radius: 20px;
          padding-left: 10px;
          color: #969495;
          font-size: 26px;
        }
        .item_border {
          font-size: 28px;
          border: 1PX solid #969495;
          color: #969495;
          border-radius: 10px;
          padding: 10px 85px 10px 25px;
          margin-right: 24px;
          box-sizing: border-box;
        }

      }
    }
  }
}
</style>
