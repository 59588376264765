<template>
  <div class="infoForm">
    <div class="container_box_item">
      <img src="#" alt="">
      <div class="item_label"><span style="color:red;">*</span>所属地区：</div>
      <div class="item">
        <div class="inp" style="line-height: 0.57333rem;" @click="onOpen('diqu')" v-if="value.length === 0">
          请选择所属地区
        </div>
        <div class="inp" style="line-height: 0.57333rem;" @click="onOpen('diqu')" v-if="value.length !== 0">
          {{ value }}
        </div>
      </div>
    </div>
    <div class="container_box_item">
      <img src="#" alt="">
      <div class="item_label"><span style="color:red;">*</span>所属学校：</div>
      <div class="item">
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          @click="onOpen('school')"
          v-if="value.length === 0"
        >
          请选择所属学校
        </div>
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          @click="onOpen('school')"
          v-if="value.length !== 0"
        >
          {{ schoolValue }}
        </div>
      </div>
    </div>
    <div class="container_box_item">
      <img src="#" alt="">
      <div class="item_label"><span style="color:red;">*</span>所属年级：</div>
      <div class="item">
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          v-if="value.length === 0"
        >
          请选择所属年级
        </div>
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          v-if="value.length !== 0"
        >
          {{ levelValue }}
        </div>
      </div>
    </div>
<!--    <div class="container_box_item">
      <img src="../assets/img/chengyuan.png" alt="">
      <div class="item_label"><span style="color:red;">*</span>所属班级：</div>
      <div class="item">
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          v-if="value.length === 0"
        >
          请选择所属班级
        </div>
        <div
          class="inp"
          style="line-height: 0.57333rem;"
          v-if="value.length !== 0"
        >
          {{ classValue }}
        </div>
      </div>
    </div>-->
    <div class="container_box_item">
      <img src="#" alt="">
      <div class="item_label" ><span style="color:red;">*</span>姓&#12288;&#12288;名：</div>
      <div class="item">
        <input type="text" class="inp" placeholder="请输入您的姓名" v-model="formData.username" :disabled="!btnFlag1">
      </div>
    </div>
    <div class="container_box_item">
      <img src="#" alt="">
      <div class="item_label"><span style="color:red;">*</span>导师姓名：</div>
      <div class="item">
        <input type="text" class="inp" placeholder="请输入您的辅导老师姓名" v-model="formData.teacher" :disabled="!btnFlag1">
      </div>
    </div>
<!--    <div class="container_box_item">
      <img src="../assets/img/diqu.png" alt="">
      <div class="item_label"><span style="color:red;">*</span>导师电话：</div>
      <div class="item">
        <input type="text" class="inp" placeholder="请输入您的辅导老师联系电话" v-model="formData.teacher_mobile">
      </div>
    </div>
    <div class="container_box_item">
      <img src="../assets/img/diqu.png" alt="">
      <div class="item_label">家长电话：</div>
      <div class="item">
        <input type="text" class="inp" placeholder="请输入您的家长联系电话" v-model="formData.parent_mobile">
      </div>
    </div>-->
    <div class="container_box_ti_btn">
      <van-button
        v-if="btnFlag1"
        class="btn_style btn_32"
        color="linear-gradient(to top, #b384ff, #448aff)"
        @click="handleClick"
        style="letter-spacing: 0;"
      >
        提交
      </van-button>
      <van-button
          v-if="btnFlag"
          class="btn_style btn_32"
          color="linear-gradient(to top, #b384ff, #448aff)"
          @click="dialogClick"
          style="letter-spacing: 0;"
      >
        下载奖状
      </van-button>
    </div>

    <van-popup
      v-model="show2"
      style="width: 90%;"
      :safe-area-inset-bottom="true"
      @close="src = ''"
      @click-overlay="src = ''"
    >
      <div class="form_box">
        <img :src="src" style="width: 100%;object-fit: cover;display: block;">
        <div style="text-align: center;font-size: 16px;">长按保存图片</div>
      </div>
    </van-popup>

    <van-popup v-model="show" round position="bottom" :safe-area-inset-bottom="true" @click-overlay="onClose">
      <van-area :area-list="areaList" @cancel="onClose" @confirm="confirm" :value="formData.province"/>
    </van-popup>

    <van-popup
      v-model="schoolShow"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
      @click-overlay="onClose"
    >
      <van-picker
        title="请选择所属学校"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        value-key="name"
        ref="picker"
      />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { Dialog, Toast } from 'vant';
import {
  getSchoolInfo,
  getInfo,
  uploadData
} from '../api'
export default {
  name: "infoForm",
  data() {
    return {
      areaList,
      value: '请选择所属地区',
      schoolValue: '请选择所属学校',
      levelValue: '请选择所属年级',
      classValue: '请选择所属班级',
      show: false,
      schoolShow: false,
      cascaderValue: '',
      name: '姓&#32;名：',
      formData: {
        username: '', //	姓名
        province: '440303', //	省份
        city: '', //	城市
        area: '', //	地区
        grade_id: '', 	//ID
        teacher: '', //	辅导老师姓名
        teacher_mobile: '', //	辅导老师联系电话
        parent_mobile: '', //	家长联系电话
      },
      columns: [],
      schoolColumns: [],
      levelColumns: [],
      flag: false,
      src: '',
      show2: false,
      is_build: false
    }
  },
  props: {
    btnFlag: {
      type: Boolean,
      default: false
    },
    btnFlag1: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (localStorage.getItem('token') != null) {
      this.get_Info()
      this.flag = false
    } else {
      this.flag = true
    }
    this.get_SchoolInfo()
  },
  methods: {
    get_Info() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
        overlay: true
      })
      getInfo({
        token: localStorage.getItem('token')
      }).then(res => {
        if (res.data.data.is_build == 0) {
          this.is_build = true
        } else {
          this.is_build = false
        }
        let data = res.data.data
        console.log(data)
        this.formData.username = data.username
        this.value = data.province + data.city + data.area
        this.schoolValue = data.grade.school.name
        this.levelValue = data.grade.name
        this.formData.teacher = data.teacher
        this.formData.teacher_mobile = data.teacher_mobile
        this.formData.parent_mobile = data.parent_mobile
        this.formData.grade_id = data.grade.id
        this.formData.province = data.province
        this.formData.city = data.city
        this.formData.area = data.area
        this.$emit('btnInfo', res.data.data.is_build)
        Toast.clear()
      })
    },
    onOpen(type) {
      if (this.btnFlag1) {
        if (type === 'diqu') {
          this.show = true
        } else if (type === 'school') {
          this.schoolShow = true
        }
      }

    },
    onClose() {
      this.show = false
    },
    confirm(e) {
      console.log(e)
      this.value = ''
      this.formData.province = e[0].name
      this.formData.city = e[1].name
      this.formData.area = e[2].name
      e.forEach(item => {
        this.value += item.name
      })
      this.show = false
    },
    onConfirm(e, value) {
      this.schoolValue = e[1]
      this.levelValue = e[2]
      this.classValue = e[2]
      this.formData.grade_id = this.columns[value[0]].children[value[1]].children[value[2]].id
      this.schoolShow = false
    },
    onCancel() {
      this.schoolShow = false
    },
    get_SchoolInfo() {
      getSchoolInfo().then(res => {
        console.log(res)
        let data = res.data.data
        this.columns = data
      })
    },
    handleClick() {
      const {
        username,
        province,
        city,
        area,
        grade_id,
        teacher,
        teacher_mobile,
        parent_mobile
      } = this.formData
      if (this._.isEmpty(province)) {
        this.$toast.fail('请选择省份')
      } else if (grade_id.length === 0) {
        this.$toast.fail('请选择学校班级')
      } else if (this._.isEmpty(username)) {
        this.$toast.fail('请输入姓名')
      } else if (this._.isEmpty(teacher)) {
        this.$toast.fail('请输入导师姓名')
      } else {
        this.$emit('formClick', this.formData)
      }
    },
    dialogClick() {
      if (this.is_build) {
        Dialog.confirm({
          message: '你有且仅有这一次修改个人信息的机会，是否确定无误？',
          confirmButtonText: '确认',
          cancelButtonText: '修改'
        })
          .then(() => {
            this.handleUpload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.handleUpload()
      }

    },
    handleUpload() {
      if (localStorage.getItem('token') == null) {
        this.$toast.fail('请登录后操作!')
        return
      }
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '图片加载中...',
        overlay: true
      })
      uploadData().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$toast.fail(res.data.msg)
        } else {
          let url = 'https://api.qingshaoniandati.kc87.com'
          this.show2 = true
          if (res.data.data[0] == 'h') {
            this.src = res.data.data
          } else if (res.data.data[0] == '/') {
            this.src = url + res.data.data
          }
          Toast.clear()
          this.get_Info()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.infoForm {
  background-color: #fff;
  .container_box_item {
    display: flex;
    align-items: center;
    margin-bottom: 38px;

    .item_label {
      font-size: 16px;
      font-weight: bold;
    }

    img {
      width: 52px;
      height: 52px;
    }

    .item {
      display: flex;
      align-items: center;

      .inp {
        width: 320px;
        height: 43px;
        border: 1PX solid #969495;
        border-radius: 20px;
        padding-left: 10px;
        color: #969495;
        font-size: 16px;
      }

      .item_border {
        font-size: 28px;
        border: 1PX solid #969495;
        color: #969495;
        border-radius: 10px;
        padding: 10px 85px 10px 25px;
        margin-right: 24px;
        box-sizing: border-box;
      }

    }
  }
  .container_box_ti_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 408px;
    }
  }
  ::v-deep .van-picker__toolbar {
    height: 44PX;
  }
  ::v-deep .van-picker__cancel {
    font-size: 16PX;
  }
  ::v-deep .van-picker__confirm {
    font-size: 16PX;
  }
  ::v-deep .van-picker-column {
    font-size: 16PX;
  }
  ::v-deep .van-ellipsis {
    overflow: visible;
  }
  ::v-deep .van-ellipsis {
    white-space: pre-wrap;
  }
  .btn_style {
    width: 408px;
    height: 42PX;
    line-height: 80px;
    border: 5px solid #fff!important;
    border-radius: 60px;
    color: #fff;
    font-weight: bolder;
    font-family: "微软雅黑";
    letter-spacing: 6px;
    margin-top: 20px;
  }
  .btn_37 {
    font-size: 37px!important;
  }
  .form_box {
    padding: 48px 24px 48px 25px;
    width: 100%;
    box-sizing: border-box;
  }
  .van-popup {
    border-radius: 10px;
  }
}
</style>
